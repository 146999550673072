import { WHATSAPP_SCOPE } from "../../facebook/config";
import { WHATSAPP_ENVIROMENTS } from "../config";

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

export async function launchWhatsAppSignup(callback: (res: any) => void) {
  const auth0Options = {
    config_id: "1138875521271939", // Asegúrate de que este es el config_id correcto
    appId: "400801087311687", // Tu App ID
    response_type: "code", // Flujo de autorización basado en "code"
    autoLogAppEvents: true,
    xfbml: true,
    version: "v22.0", // Usa la versión más reciente de la Graph API
    scope:
      "whatsapp_business_management,whatsapp_business_messaging,public_profile", // Permisos necesarios para WhatsApp
    override_default_response_type: true, // Asegúrate de que esto esté activado para que se devuelva un "code"
    extras: {
      setup: {},
      featureType: "",
      sessionInfoVersion: "3",
    },
  };


  window.FB.login(callback, auth0Options);
}
