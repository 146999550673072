import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import {
  WHATSAPP_GET_LOADING,
  WHATSAPP_SYNC_LOADING,
} from "../../../../constants/loading.constant";
import { useGlobalCore } from "../../../../cores/globals";
import { useMeCore } from "../../../../cores/me";
import {
  SocialProviderId,
  SocialProviderName,
} from "../../../../cores/socialPost/config";
import { useIntl } from "react-intl";
import { SocialProvider } from "../../../../cores/socialPost/interfaces/socialProvider";
import SocialCard from "../SocialCard";
import { WhatsappIcon } from "./WhatsappIcon";
import { useWhatsappCore } from "../../../../cores/whatsapp";
import { useCompanyCore } from "../../../../cores/company";
import { launchWhatsAppSignup } from "../../../../cores/whatsapp/services/whatsapp-auth0-config";
import { UpgradePlanButton } from "../../plan/UpgradePlanButton";

export default function WhatsappBindCard() {
  const intl = useIntl();
  const { companyRole, companyPlan } = useMeCore();
  const { get, settings, unbind, completeAuth0Step } = useWhatsappCore();
  const { company } = useMeCore();
  const { getCompanyIntegration } = useCompanyCore();
  const { isLoading } = useGlobalCore();

  useEffect(() => {
    get(company!.id);
  }, [company, get]);

  const planAllowsWhatsapp = useMemo(
    () =>
      (companyRole?.owner || companyRole?.admin) &&
      companyPlan?.rules.allowWhatsappChat,
    [companyPlan, companyRole]
  );
  const isActive = useMemo((): boolean => !_.isEmpty(settings), [settings]);

  // TODO USE THIS LATER
  // const handleOnCompleted = useCallback(
  //   async (data: WhatsappAuthResponse) => {
  //     const { tokenDetail } = data;
  //     bind(company!.id, tokenDetail.accessToken, tokenDetail.userID, () => {
  //       getCompanyIntegration(company?.id!);
  //     });
  //   },
  //   [company, getAccounts, turnOnModal]
  // );
  const handleUnbind = useCallback(async () => {
    unbind(company?.id!, () => {
      getCompanyIntegration(company?.id!);
    });
  }, [company, getCompanyIntegration, unbind]);

  const socialProvider = useMemo(
    (): SocialProvider => ({
      id: SocialProviderId.Whatsapp,
      name: SocialProviderName.Whatsapp,
      icon: <WhatsappIcon size="6x" active={isActive} />,
      displayName: settings?.phoneNumber,
    }),
    [settings, isActive]
  );
  const handleAuth0 = async () =>
    launchWhatsAppSignup(({ authResponse }: any) => {
      if (authResponse) {
        console.log({ authResponse });
        completeAuth0Step(company?.id!, authResponse.code);
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    });
  return (
    <SocialCard
      social={socialProvider}
      bindLoading={isLoading(WHATSAPP_SYNC_LOADING)}
      getLoading={isLoading(WHATSAPP_GET_LOADING)}
      showSynchronizer={true}
      videoId="Zwo5zWGBW-g"
      remainingBindDays={null}
      canSync={false}
      planAllowsFeature={planAllowsWhatsapp}
    >
      {!settings?.token ? (
        <div className="w-100 px-2">
          {planAllowsWhatsapp ? (
            <Button
              className="w-100 mx-auto"
              color="primary"
              onClick={handleAuth0}
            >
              {intl.formatMessage({ id: "app.home.syncUp" })}
            </Button>
          ) : (
            <UpgradePlanButton expanded />
          )}
        </div>
      ) : (
        <button
          className="btn btn-outline-primary  w-100 mx-auto"
          color="primary"
          onClick={handleUnbind}
        >
          {intl.formatMessage({ id: "app.home.disconnect" })}
        </button>
      )}
    </SocialCard>
  );
}
